<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark>
        <strong v-if="isModificar">Modificar subárea</strong>
        <strong v-else>Agregar subárea</strong>
      </v-toolbar>
      <v-container>
        <v-text-field
          dense
          outlined
          v-model="nombre"
          label="Nombre"
          :disabled="loading"
          :error-messages="nombreErrors"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
        ></v-text-field>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn small color="primary" :loading="loading" @click="(isModificar ? modificarSubarea() : agregarSubarea())">
          <span v-if="isModificar">Modificar</span>
          <span v-else>Agregar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { agregarPermiso, modificarPermiso } from "../permisos.service";

export default {
  props: {
    mostrar: Boolean,
    modelo: { type: String, required: true },
    idArea: { type: String, required: true },
    isModificar: { type: Boolean, default: false },
    data: { type: Object, default: () => {} },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["app"]),
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    nombre: { required },
  },
  data: () => ({
    loading: false,
    nombre: null,
  }),
  created() {
    if(this.isModificar){
      if(this.data === {}) throw new Error("Es necesario el viejo nombre");
      this.nombre = this.data.nombre;
    }
  },
  methods: {
    async agregarSubarea() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loading = true;
          const sr = await agregarPermiso("subarea", {
            modelo: this.modelo,
            idArea: this.idArea,
            nombre: this.nombre,
          });
          this.loading = false;
          if (!sr.ok) {
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.$emit("subareaAgregada", sr.nuevoPermiso);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarSubarea() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loading = true;
          const sr = await modificarPermiso(this.data._id, "subarea", {
            modelo: this.data.modelo,
            nombre: this.nombre,
          });
          this.loading = false;
          if (!sr.ok) {
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.$emit("subareaModificada", sr.permisoModificado);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>