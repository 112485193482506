<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dense
        flat
        dark
        class="text-h6"
        :loading="loading"
      >
        Permisos
      </v-toolbar>
      <v-progress-linear v-if="loading" indeterminate color="secondary" />
      <v-container>
        <v-row>
          <v-col cols="4">
            <selectorArea
              v-model="modeloEducativo"
              :areas="modelosEducativos"
              :loading="loading"
              etiqueta="Modelo educativo"
              @change="cargarPermisosArea"
            />
          </v-col>
          <v-col cols="4">
            <selectorArea
              v-model="permisoArea"
              :areas="permisoAreas"
              :menu="true"
              :loading="loading"
              @agregarArea="mostrarAgregarArea = true"
              @modificarArea="handleModificar('area')"
              @change="cargarPermisosSubrea"
            />
          </v-col>
          <v-col cols="4">
            <selectorArea
              v-model="permisoSubarea"
              :areas="permisoSubareas"
              :menu="true"
              etiqueta="Subarea"
              :loading="loading"
              @agregarArea="mostrarAgregarSubarea = true"
              @modificarArea="handleModificar('subarea')"
              @change="cargarPermisos"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12" class="text-right">
            <v-btn
              small
              color="primary"
              :loading="loading"
              @click="handleAgregarPermiso"
            >
              Agregar Permiso
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12">
            <datatable
              :loading="loading"
              :datos="permisos"
              :pagination="pagination"
              :headers="headers"
              :page="pagination.page"
              :totalItems="pagination.itemsLength"
              :itemsPerPage="pagination.itemsPerPage"
              hideSearch
            >
              <template #item-nombre="{item}">
                {{item.nombre}}
                <v-chip v-if="item.simple" color="blue darken-2" x-small outlined class="ml-2">
                  Simple
                </v-chip>
                <v-chip v-if="item.carreras" color="red darken-2" x-small outlined class="ml-2">
                  Carreras
                </v-chip>
                <v-chip v-if="item.departamentos" color="black darken-2" x-small outlined class="ml-2">
                  Departamentos
                </v-chip>
                <v-chip v-if="item.sedes" color="orange darken-2" x-small outlined class="ml-2">
                  Sedes
                </v-chip>
              </template>
              <template #item-valueMenu="{ item }">
                <integranteMenuComponent
                  :loading="loading"
                  @action="handleModificar('permiso', item)"
                />
              </template>
            </datatable>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <agregarArea
      v-if="mostrarAgregarArea"
      :mostrar="mostrarAgregarArea"
      :modelo="modeloEducativo"
      :isModificar="isModificarArea"
      :data="modificarAreaData"
      @cancelar="mostrarAgregarArea = false; isModificarArea = false;"
      @areaAgregada="(e) => permisoAreaAgregada(e)"
      @areaModificada="(e) => permisoAreaModificada(e)"
    />
    <agregarSubarea
      v-if="mostrarAgregarSubarea"
      :mostrar="mostrarAgregarSubarea"
      :modelo="modeloEducativo"
      :idArea="permisoArea"
      :isModificar="isModificarSubarea"
      :data="modificarSubareaData"
      @cancelar="mostrarAgregarSubarea = false; isModificarSubarea = false;"
      @subareaAgregada="(e) => permisoSubareaAgregada(e)"
      @subareaModificada="(e) => permisoSubareaModificada(e)"
    />
    <agregarPermiso
      v-if="mostrarAgregarPermiso"
      :mostrar="mostrarAgregarPermiso"
      :modelo="modeloEducativo"
      :idArea="permisoArea"
      :idSubarea="permisoSubarea"
      :isModificar="isModificarPermiso"
      :data="modificarPermisoData"
      @cancelar="mostrarAgregarPermiso = false; isModificarPermiso = false;"
      @permisoAgregado="(e) => permisoAgregado(e)"
      @permisoModificado="(e) => permisoModificado(e)"
    />
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters} from 'vuex'

import selectorArea from "../globals/template/widgets/selectorAreas.vue";
import datatable from "../globals/template/widgets/datatable.vue";
import agregarArea from "./dialogs/agregarArea.vue";
import agregarSubarea from "./dialogs/agregarSubarea.vue";
import agregarPermiso from "./dialogs/agregarPermiso.vue";
import integranteMenuComponent from "./integranteMenu.vue";

import { cargarModelosEducativos, obtenerPermisosTipo, obtenerPermisos } from "./permisos.service";

export default {
  name: "Permisos",
  components: {
    selectorArea,
    agregarArea,
    agregarSubarea,
    agregarPermiso,
    datatable,
    integranteMenuComponent,
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  watch: {
    sessionToken() {
      this.cargarModelos();
    },
  },
  mounted() {
    if (this.sessionToken) this.cargarModelos();
  },
  data() {
    return {
      loading: false,
      mostrarAgregarArea: false,
      mostrarAgregarSubarea: false,
      mostrarAgregarPermiso: false,
      isModificarArea: false,
      isModificarSubarea: false,
      isModificarPermiso: false,
      modificarAreaData: {},
      modificarSubareaData: {},
      modificarPermisoData: {},
      modeloEducativo: "",
      modelosEducativos: [],
      permisoArea: "",
      permisoAreas: [],
      permisoSubarea: "",
      permisoSubareas: [],
      permisos: [],
      pagina: 1,
      busqueda: "",
      headers: [
        {
          text: "Numero",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "100px",
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
          filterable: false,
          width: "800px",
        },
        { text: "", value: "valueMenu", sortable: false, filterable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
    };
  },
  methods: {
    async cargarModelos() {
      try {
        this.loading = true;
        const sr = await cargarModelosEducativos();
        this.loading = false;
        this.modelosEducativos = sr.modelosEducativos;
        if (this.modelosEducativos.length > 0)
          this.modeloEducativo = this.modelosEducativos[0]._id;
        await this.cargarPermisosArea();
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
    async cargarPermisosArea() {
      try {
        this.loading = true;
        const sr = await obtenerPermisosTipo('area', { modelo: this.modeloEducativo });
        this.loading = false;
        this.permisoAreas = sr.permisos;
        if (this.permisoAreas.length > 0)
          this.permisoArea = this.permisoAreas[0]._id;
        else
          this.permisoArea = "";
        await this.cargarPermisosSubrea();
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
    async cargarPermisosSubrea() {
      try {
        this.loading = true;
        const sr = await obtenerPermisosTipo('subarea', { modelo: this.modeloEducativo, idArea: this.permisoArea });
        this.loading = false;
        this.permisoSubareas = sr.permisos;
        if (this.permisoSubareas.length > 0)
          this.permisoSubarea = this.permisoSubareas[0]._id;
        else
          this.permisoSubarea = "";
        await this.cargarPermisos();
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
    async cargarPermisos(){
       try {
        this.loading = true;
        const sr = await obtenerPermisos({
          modelo: this.modeloEducativo,
          idArea: this.permisoArea,
          idSubarea: this.permisoSubarea,
        });
        this.loading = false;
        if(sr.ok){
          const { total, pageStop, pageStart, permisos } = sr;
          this.permisos = permisos;
          this.pagination.itemsLength = total;
          this.pagination.pageStop = pageStop;
          this.pagination.pageStart = pageStart;
        }else{
          this.$systemErrorMessage(sr.mensaje);
        }
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
    handleAgregarPermiso(){
      if(this.permisoArea != "" && this.permisoSubarea != "")
        this.mostrarAgregarPermiso = true;
      else
        this.$systemErrorMessage("Es necesario seleccionar un area y una subarea para crear un nuevo permiso");
    },
    handleModificar(tipo, item = null){
      if(tipo === 'area'){
        this.mostrarAgregarArea = true;
        this.isModificarArea = true;
        this.modificarAreaData = this.permisoAreas.find( p => p._id === this.permisoArea);
      }else if(tipo === 'subarea'){
        this.mostrarAgregarSubarea = true;
        this.isModificarSubarea = true;
        this.modificarSubareaData = this.permisoSubareas.find( p => p._id === this.permisoSubarea);
      }else if(tipo === 'permiso'){
        this.mostrarAgregarPermiso = true;
        this.isModificarPermiso = true;
        this.modificarPermisoData = item;
      }
    },
    permisoAreaAgregada(area){
      this.permisoAreas.push(area);
      this.mostrarAgregarArea = false;
      this.isModificarArea = false;
    },
    permisoSubareaAgregada(subarea){
      this.permisoSubareas.push(subarea);
      this.mostrarAgregarSubarea = false;
      this.isModificarSubarea = false;
    },
    permisoAgregado(permiso){
      this.permisos.push(permiso);
      this.mostrarAgregarPermiso = false;
      this.isModificarPermiso = false;
    },
    permisoAreaModificada(area){
      const index = this.permisoAreas.findIndex( pa => pa._id === area._id);
      this.permisoAreas.splice(index, 1, area);
      this.mostrarAgregarArea = false;
      this.isModificarArea = false;
    },
    permisoSubareaModificada(area){
      const index = this.permisoSubareas.findIndex( ps => ps._id === area._id);
      this.permisoSubareas.splice(index, 1, area);
      this.mostrarAgregarSubarea = false;
      this.isModificarSubarea = false;
    },
    permisoModificado(permiso){
      const index = this.permisos.findIndex( p => p._id === permiso._id);
      this.permisos.splice(index, 1, permiso);
      this.mostrarAgregarPermiso = false;
      this.isModificarPermiso = false;
    }
  },
};
</script>
