<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark>
        <strong v-if="isModificar">Modificar permiso</strong>
        <strong v-else>Agregar permiso</strong>
      </v-toolbar>
      <v-container>
         <v-row>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              v-model="nombre"
              label="Nombre"
              :disabled="loading"
              :error-messages="nombreErrors"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
            />
          </v-col>
         </v-row>
         <v-row>
          <v-col cols="12">
            <v-checkbox
              v-model="simple"
              :label="'Simple'"
              :color="'#0d3863'"
              class="removeMargin"
              :disabled="loading"
              @change="simpleChange()"
            />
            <v-checkbox
              v-model="carreras"
              :label="'Carreras'"
              :color="'#0d3863'"
              class="removeMargin"
              :disabled="simple"
            />
            <v-checkbox
              v-model="departamentos"
              :label="'Departamentos'"
              :color="'#0d3863'"
              class="removeMargin"
              :disabled="simple"
            />
            <v-checkbox
              v-model="sedes"
              :label="'Sedes'"
              :color="'#0d3863'"
              class="removeMargin"
              :disabled="simple"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn small color="primary" :loading="loading" @click="(isModificar ? handleModificarPermiso() : handleAgregarPermiso())">
          <span v-if="isModificar">Modificar</span>
          <span v-else>Agregar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { agregarPermiso, modificarPermiso } from "../permisos.service";

export default {
  props: {
    mostrar: Boolean,
    modelo: { type: String, required: true },
    idArea: { type: String, required: true },
    idSubarea: { type: String, required: true },
    isModificar: { type: Boolean, default: false },
    data: { type: Object, default: () => {} },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["app"]),
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    nombre: { required },
  },
  data: () => ({
    loading: false,
    nombre: "",
    simple: true,
    carreras: false,
    departamentos: false,
    sedes: false,
  }),
  created() {
    if(this.isModificar){
      if(this.data === {}) throw new Error("Es necesario el viejo nombre");
      this.nombre = this.data.nombre;
      this.simple = this.data.simple;
      this.carreras = this.data.carreras;
      this.departamentos = this.data.departamentos;
      this.sedes = this.data.sedes;
    }
  },
  methods: {
    async handleAgregarPermiso() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loading = true;
          const sr = await agregarPermiso("permiso", {
            modelo: this.modelo,
            idArea: this.idArea,
            idSubarea: this.idSubarea,
            nombre: this.nombre,
            simple: this.simple,
            carreras: this.carreras,
            departamentos: this.departamentos,
            sedes: this.sedes,
          });
          this.loading = false;
          if (!sr.ok) {
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.$emit("permisoAgregado", sr.nuevoPermiso);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async handleModificarPermiso() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loading = true;
          const sr = await modificarPermiso(this.data._id, "permiso", {
            modelo: this.modelo,
            idArea: this.idArea,
            idSubarea: this.idSubarea,
            nombre: this.nombre,
            simple: this.simple,
            carreras: this.carreras,
            departamentos: this.departamentos,
            sedes: this.sedes,
          });
          this.loading = false;
          if (!sr.ok) {
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.$emit("permisoModificado", sr.permisoModificado);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    simpleChange() {
      if (this.simple) {
        this.departamentos = false;
        this.sedes = false;
        this.carreras = false;
      }
    },
  },
};
</script>
<style scoped>
.removeMargin {
  margin: 0;
  padding: 0;
}
</style>
