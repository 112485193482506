import {
    getServerRequest,
    postServerRequest,
    deleteServerRequest,
    putServerRequest,
} from '../globals/services/serverRequest.service';
import { store } from '@/store/store';
const { httpURL } = store.getters;

export async function obtenerPermisos(filtros) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    let url = `${httpURL}/permisos`;
    return await postServerRequest(url, filtros, config);
}

export async function obtenerPermisosTipo(tipo, filtros) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    let query = '';
    if(filtros.hasOwnProperty('modelo')){
        query = `?modelo=${filtros.modelo}`;
    }
    if(filtros.hasOwnProperty('idArea')){
        query = `?modelo=${filtros.modelo}&idArea=${(filtros.idArea === "" ? null : filtros.idArea)}`;
    }
    const url = `${httpURL}/permisos/${tipo}${query}`;
    return await getServerRequest(url, config);
}

export async function agregarPermiso(tipo, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/permisos/${tipo}`;
    return await postServerRequest(url, data, config);
}

export async function modificarPermiso(idPermiso, tipo, data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/permisos/${tipo}/${idPermiso}`;
    return await putServerRequest(url, data, config);
}

export async function cargarModelosEducativos() {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/escuela/modelos`;
    const data = { headers: { token: sessionToken } }
    return await getServerRequest(url, data);
}