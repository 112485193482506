<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" :loading="loading">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="$emit('action', 'modificar');">
          <template>
            <v-list-item-title>Modificar permiso</v-list-item-title>
            <v-list-item-icon
              ><v-icon small>mdi-pencil</v-icon></v-list-item-icon
            >
          </template>
        </v-list-item>
      </v-list>
    </v-menu>

  </div>
</template>

<script>

export default {
  name: "integranteMenu",
  props: {
    loading: Boolean,
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
